/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState, useEffect } from "react";
import { HiHeart } from "react-icons/hi";

// Internal components
import InternalOrExternalLink from "~/components/InternalOrExternalLink";
import Logo from "~/components/Logo";

// External components
import { HamburgerMinus } from "react-animated-burgers";
import { CgHomeAlt } from "react-icons/cg";
import { BsChevronDown, BsPlus } from "react-icons/bs";
import KeyboardEventHandler from "react-keyboard-event-handler";

// Contexts
import { DataContext } from "~/contexts/Data";
import AnimateHeight from "./AnimateHeight";

const Nav = () => {
  const {
    path,
    umbData,
    showAllPages,
    setShowAllPages,
    scrolled,
    scrollDirection,
    screenWidth,
    mobileMenu,
    setMobileMenu,
  } = useContext(DataContext);
  // document.querySelectorAll(".nav__menu").forEach((el) => {
  //   const delta = 6;
  //   let startX;
  //   let startY;
  //   el.addEventListener("mousedown", function (event) {
  //     startX = event.pageX;
  //     startY = event.pageY;
  //   });

  //   el.addEventListener("mouseup", function (event) {
  //     const diffX = Math.abs(event.pageX - startX);
  //     const diffY = Math.abs(event.pageY - startY);

  //     if (diffX < delta && diffY < delta) {
  //       setMobileMenu(false);
  //     }
  //   });
  // });
  let showAllTop = umbData.showAllTop;
  useEffect(() => {
    if (showAllTop) {
      setShowAllPages(true);
    }
  }, [showAllTop]);
  const [visible, setVisible] = useState();
  useEffect(() => {
    if (showAllPages && umbData.topMenu) {
      setVisible(umbData.topMenu);
    } else if (umbData.children) {
      setVisible(umbData.children);
    }
  }, [showAllPages, umbData]);

  const [clicked, setClicked] = useState("0");

  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked("0");
    }
    setClicked(index);
  };

  return (
    <>
      <KeyboardEventHandler
        handleKeys={["esc"]}
        onKeyEvent={(key, e) => {
          setMobileMenu(false);
        }}
      />
      <nav
        className={`nav container-lg px-4 px-sm-5 px-lg-0 ${scrolled > 100 ? "nav--scrolled" : ""
          } ${mobileMenu ? "nav--mobile-open" : ""}
        `}
      >
        {/* {umbData.sokNa?.url && (
          <InternalOrExternalLink
            to={umbData.sokNa.url}
            className="btn btn--blue-gradient d-none d-lg-block"
          >
            {umbData.sokNa.label}
            <BsPlus size="3rem" className="btn__icon btn__icon--lg ms-2" />
          </InternalOrExternalLink>
        )} */}
        <Logo
          onClick={() => setMobileMenu(false)}
          className={`d-lg-none ${scrolled > 400 && scrollDirection === "down" && !mobileMenu
            ? "hide"
            : ""
            }`}
        />
        <HamburgerMinus
          isActive={mobileMenu}
          toggleButton={(e) => {
            setMobileMenu(!mobileMenu);
          }}
          barColor="white"
          className={`nav__hamburger-btn d-lg-none ${scrolled > 400 && scrollDirection === "down" && !mobileMenu
            ? "hide"
            : ""
            } `}
        />
        <div
          className={`nav__menu ${mobileMenu ? "nav__menu--show" : ""} 
        ${scrolled > 400 && scrollDirection === "down" && !mobileMenu
              ? "hide"
              : ""
            }`}
        >
          {" "}
          <div className="nav__top-menu d-none d-lg-flex">
            <Logo />
          </div>
          <div className="nav__main-menu">
            <InternalOrExternalLink
              onClick={() => setMobileMenu(false)}
              to="/"
              className={`nav__menu__item  d-none d-lg-inline-block ${screenWidth < 991.98 ? "pb-4" : ""
                } ${path === "/" ? "nav__menu__item--current" : ""}`}
            >
              <CgHomeAlt
                size={screenWidth < 991.98 ? "2rem" : "1rem"}
                className="btn__icon--lg"
              />
            </InternalOrExternalLink>

            {visible &&
              visible.map(
                (page, index) =>
                  !page.umbracoNaviHide && (
                    <div
                      className="position-relative"
                      key={page.id + "menu-item"}
                    >
                      <div className="nav__menu__item-wrapper">
                        <InternalOrExternalLink
                          onClick={() => setMobileMenu(false)}
                          to={page.url}
                          arrowClassName={"d-lg-none"}
                          className={`nav__menu__item ${path && path.includes(page.url)
                            ? "nav__menu__item--current"
                            : ""
                            }`}
                        >
                          <span className="nav__menu__item__label">
                            {" "}
                            {page.title}
                            {page.children && (
                              <BsChevronDown
                                size="1rem"
                                className="ms-2 d-none d-lg-inline-block"
                              />
                            )}
                          </span>
                        </InternalOrExternalLink>
                        {page.children && (
                          <div
                            className="ps-3 d-lg-none nav__menu__dropdown-icon"
                            onClick={() => {
                              handleToggle(index);
                            }}
                          >
                            <span>
                              <BsChevronDown size="1rem" />
                            </span>
                          </div>
                        )}
                      </div>
                      {page.children && (
                        <AnimateHeight
                          isVisible={
                            clicked === index ||
                            (screenWidth && screenWidth > 991.98)
                          }
                        >
                          <ul
                            className={`nav__menu__submenu ${clicked === index
                              ? "nav__menu__submenu--visible"
                              : ""
                              }`}
                          // ref={(el) => (itemsRef.current[index] = el)}
                          >
                            {page.children?.map(
                              (subPage) =>
                                !subPage.umbracoNaviHide && (
                                  <InternalOrExternalLink
                                    key={subPage.id + "submenu-item"}
                                    onClick={() => setMobileMenu(false)}
                                    // showArrow
                                    to={subPage.url}
                                    className="nav__menu__submenu__item"
                                  >
                                    {subPage.title}
                                  </InternalOrExternalLink>
                                )
                            )}
                          </ul>
                        </AnimateHeight>
                      )}
                    </div>
                  )
              )}
            <div className="nav_extra-buttons">
              {umbData.sokNa?.url && (
                <InternalOrExternalLink
                  onClick={() => setMobileMenu(false)}
                  to={umbData.sokNa.url}
                  className="btn btn--blue-gradient d-none d-lg-inline-block"
                >
                  {umbData.sokNa.label}
                  <BsPlus
                    size="1.5em"
                  />
                </InternalOrExternalLink>
              )}
              {umbData.give?.url &&
                <InternalOrExternalLink to={umbData.give?.url} className="btn btn--light-red btn--start d-none d-lg-inline-block">

                  <span>Gi en gave</span>
                  <HiHeart
                    size="1em"
                  />
                </InternalOrExternalLink>
              }
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;
